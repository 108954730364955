import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import getMetatags from "../util/getMetatags"
import Seo from "../components/Seo"
import Image from "../components/Image"
import { CareersHero } from "../components/hero"
import { BlockWithBlueShapes } from "../components/cta-block"
import axios from "axios"

const CareersPage = ({ data: { nodeCareers } }) => {

  const [jobList, setJobList] = useState([])
  const [loading, setLoading] = useState(true)

  const seo = getMetatags(nodeCareers.metatag, nodeCareers.relationships.OGImage?.localFile?.publicURL)
  const { hero, section } = nodeCareers.relationships
  //const { showCTA } = nodeCareers

  //.log(showCTA)

  useEffect(() => {

    async function fetchData() {

      const jobs0 = await getComeetJobs();
      const jobs = jobs0.map(e => ({
        department: e.department,
        title: e.name,
        location: [e.city, e.state, e.name].join(' '),
        employment_type: e.employment_type,
        url: e.url_comeet_hosted_page
      }))

      /*
      const jobs0 = await getJobs()
      const jobs = jobs0.map(e => ({
        department: e.department,
        title: e.title,
        location: [e.city, e.state, e.country].join(' '),
        employment_type: e.employment_type,
        url: e.url
      }))
      */

      setJobList(jobs)
      setLoading(false)

    }

    fetchData()

  }, [])

  return (
    <Layout showCta={false}>
      <Seo {...seo} />
      <CareersHero {...hero} />
      <Half {...section} />

      <section className=" lg:block py-12 lg:py-28   bg-no-repeat  bg-left-top ">
        <div className="container">
          <h2 className="mx-auto text-center text-4xl lg:text-6xl font-bold	pt-0 lg:pt-12">Find your <span className="text-blue">perfect fit.</span></h2>
          <div className="pt-6 lg:pt-32">
            {loading && <div>Loading</div>}
            <Jobs items={jobList} />
          </div>
        </div>
      </section>

      {false && <BlockWithBlueShapes title={'Come for the mission,<br/> stay for the people'} link={{ uri: '/', title: 'Join us!' }} />}
    </Layout >
  )
}

const getComeetJobs = async () => {

  const token = process.env.GATSBY_COMEET_TOKEN

  try {
    const response = await axios.get('https://www.comeet.co/careers-api/2.0/company/88.009/positions?token=' + token);
    return response.data
  } catch (error) {
    console.error(error);
    return []
  }

}

const Half = ({ title, description, image, relationships }) => {
  return (
    <section className="" >
      <div className="container flex flex-col lg:flex-row p-0 lg:p-8 lg:border w-full lg:space-x-1 lg:max-w-[1200px]">
        <div className="lg:w-1/2 p-6 lg:p-0">
          <div className="text-3xl lg:text-4xl font-bold pt-0 lg:pt-12 pb-4 lg:max-w-[500px]">{title}</div>
          <p className="text-2xl lg:text-28 font-light lg:max-w-[500px]	">{description}</p>
        </div>


        <div className="w-full lg:w-1/2  text-right pt-4 px-0 mx-0 lg:pt-0">
          <Image image={relationships?.image} alt={image.alt} className="block lg:block-inline  p-0 m-0" />
        </div>
      </div>
    </section>
  )
}

const Jobs = ({ items }) => {

  //console.log(items)
  return (
    <div className="">
      {items.map((e, i) => (
        <div className="border-b last:border-none p-4 lg:p-8 flex flex-col lg:flex-row w-full text-lg lg:text-xl lg:font-light " key={i}>
          <div className="lg:w-1/4 ">
            <div className="">{e.department}</div>
            <h3 className="text-xl font-medium">{e.title}</h3>

          </div>
          <div className="lg:grow lg:w-2/5 lg:self-end" >{e.location}</div>

          <div className="capitalize lg:grow lg:self-end">{e.employment_type}</div>

          <div className="text-blue hover:underline lg:self-end"><a href={e.url} target={'__blank'}>Job Description</a></div>
        </div>
      ))}
    </div>
  )
}
export default CareersPage

export const query = graphql`
  query jobspage {
    nodeCareers(id: {eq: "759e8494-07b0-5c21-b4a5-871ea862c709"}) {
      title
      id
      metatag {
        attributes {
          content
          name
        }
        tag
      }
      relationships {
        hero: field_hero {
          ...ParagraphGenericBlock
        }
        section: field_paragraph_single {
          ...ParagraphGenericBlock
        }
        OGImage: field_image {
          localFile {
            url
            publicURL
          }
        }
      }

    }
  }
`
